import { motion } from "framer-motion";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const OurWorkDetailsPage = () => {
  const item = useLocation();
  const products = item?.state;
  console.log(item, "OurWorkDetailsPage");

  // Scroll to top when the component loads
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="w-screen min-h-screen flex flex-col items-center scrollbar-hide bg-gray-50">
      {products.details.map((product, idx) => (
        <div
          key={idx}
          className="w-full flex flex-col items-center mb-8"
          style={{
            backgroundColor: product.backgroundColor,
          }}
        >
          {/* Title and Description for each product */}
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
            className="text-center py-10 px-4 w-full flex justify-center"
          >
            <div className="">
              <h1 className="text-3xl md:text-4xl font-extrabold mb-4">
                {product.name}
              </h1>
              {/* Handling Alternate Green Color for Description */}
              <p className="text-lg md:text-xl text-gray-600">
                {(Array.isArray(product.description)
                  ? product.description
                  : [product.description]
                ).map((text, index) => (
                  <span
                    key={index}
                    className={
                      index % 2 === 0 ? "text-black" : "text-[#00ab2f]"
                    }
                  >
                    {text}
                  </span>
                ))}
              </p>
              {product.website && (
                <p className="text-lg md:text-xl m-6">
                  We are live on{" "}
                  <a
                    href={`https://${product.website.replace(
                      /^https?:\/\//,
                      ""
                    )}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-600 hover:underline"
                  >
                    {product.website}
                  </a>
                </p>
              )}
            </div>
          </motion.div>

          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1.5 }}
            className="flex justify-center w-full px-5 scrollbar-hide"
            style={{
              WebkitOverflowScrolling: "touch",
              overscrollBehaviorX: "contain",
              scrollSnapType: "x mandatory",
            }}
          >
            <div className="flex flex-wrap gap-6 justify-center">
              {product.images.map((src, index) => (
                <motion.img
                  key={index}
                  src={src}
                  alt={`Screenshot ${index + 1}`}
                  className="rounded-3xl shadow-md object-cover flex-shrink-0 w-[250px] h-auto md:w-auto md:h-[450px]"
                  style={{
                    scrollSnapAlign: "center",
                  }}
                />
              ))}
            </div>
          </motion.div>
        </div>
      ))}

      <style jsx>{`
        @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

        .scrollbar-hide::-webkit-scrollbar {
          display: none;
        }
        .scrollbar-hide {
          -ms-overflow-style: none;
          scrollbar-width: none;
        }

        @media (max-width: 768px) {
          .flex-shrink-0 img,
          .rounded-3xl.shadow-md.object-cover.flex-shrink-0 {
            width: 100%;
            height: auto;
          }
        }
      `}</style>
    </div>
  );
};

export default OurWorkDetailsPage;
