import { motion, AnimatePresence } from "framer-motion";
import { useState, useEffect } from "react";
import Lottie from "lottie-react";
import heroSection from "../assets/lottie/heroSection.json";
import aiagents from "../assets/png/ai-manual.png";
import ai from "../assets/png/ai.png";
import mobileApps from "../assets/png/mobile-apps.png";
import saas from "../assets/png/saas.png";
import website from "../assets/png/website.png";

export const HeroSection = ({ isIndianIP, handleScroll }) => {
  const services = [
    {
      title: "Custom AI Agents",
      description:
        "Intelligent AI assistants tailored for your business needs.",
      icon: aiagents,
    },
    {
      title: "Mobile Apps",
      description:
        "Custom-built mobile solutions for seamless user experiences.",
      icon: mobileApps,
    },
    {
      title: "AI-Powered Apps",
      description:
        "Harness the power of AI for smarter, data-driven applications.",
      icon: ai,
    },
    {
      title: "Websites and Web Apps",
      description:
        "Modern, responsive websites and web apps tailored to your needs.",
      icon: website,
    },
    {
      title: "SaaS Products",
      description: "Scalable SaaS platforms to drive business efficiency.",
      icon: saas,
    },
  ];

  const headlines = [
    "Bespoke Development",
    "Custom Software Development",
    "Custom AI Agents Development",
  ];
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prev) => (prev + 1) % headlines.length);
    }, 2000); // Change text every 2 seconds

    return () => clearInterval(interval);
  }, [headlines.length]); // ✅ Fix: Include headlines.length

  return (
    <div className="mt-[7%] font-custom bg-gray-50 flex flex-col items-center">
      <div className="container mx-auto px-6 lg:px-16 flex flex-col lg:flex-row mt-16 lg:mt-28 gap-10">
        {/* Left Section */}
        <div className="flex-1">
          {/* ✅ Reduced blank space between headlines */}

          <div className="h-[36px] overflow-hidden flex items-center">

            <AnimatePresence mode="wait">
              <motion.div
                key={headlines[index]}
                initial={{ y: "100%", opacity: 0 }}
                animate={{ y: "0%", opacity: 1 }}
                exit={{ y: "-100%", opacity: 0 }}
                transition={{ duration: 0.5, ease: "easeInOut" }}
                className="text-green-600 font-bold text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-4xl leading-tight pb-1"
              >
                {headlines[index]}
              </motion.div>
            </AnimatePresence>
          </div>

          {/* Main Heading */}
          <h1 className="font-custom text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-4xl leading-snug text-gray-800">
            MVPs, AI Solutions-
            <br /> Faster & Smarter With
            <span className="text-green-600 font-bold">
              {" "}
              <br /> Noukha
            </span>
            !
          </h1>
          <p className="text-gray-600 mt-4 text-base sm:text-lg max-w-md">
            We build Custom software, AI solutions, Tailored AI agents, and MVPs
            for startups designed to fit your vision and budget.
          </p>
          <button
            data-tally-open="wMr5Ek"
            data-tally-layout="modal"
            data-tally-width="800"
            data-tally-auto-close="2000"
            className="mt-8 hover:bg-[#43B968] text-white font-bold py-3 px-6 rounded-lg bg-[#179e42]"
          >
            Build With Us <span className="ml-4">➔</span>
          </button>
          <Lottie
            animationData={heroSection}
            loop={true}
            className="max-w-lg md:max-w-lg"
          />
        </div>

        {/* Right Section */}
        <div className="w-full lg:w-3/6 h-1/2 justify-center items-center flex flex-col">
          <div className="bg-white shadow-md rounded-lg px-8 lg:p-8 h-1/2">
            <h2 className="text-gray-600 text-base lg:text-lg mb-8 mt-8 md:mt-0">
              What We Offer
            </h2>
            <ul className="space-y-6 mb-8">
              {services.map((app, index) => (
                <motion.li
                  key={index}
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.2, duration: 0.5 }}
                  className="space-y-6"
                >
                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <img
                        src={app.icon}
                        alt="Icon"
                        className="h-16 w-16 mr-6"
                      />
                      <div>
                        <h3 className="text-gray-900 font-semibold text-lg lg:text-xl">
                          {app.title}
                        </h3>
                        <p className="text-gray-600 text-base lg:text-lg">
                          {app.description}
                        </p>
                      </div>
                    </div>
                  </div>
                  {index < services.length - 1 && (
                    <div className="border-t border-gray-300 mt-6"></div>
                  )}
                </motion.li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};