// src/App.js
import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Route, Routes, Navigate } from "react-router-dom";
import HomePage from "./pages/HomePage";
import OurDetailedServices from "./pages/OurWorkDetailedPage";

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/our-work" element={<OurDetailedServices />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export default App;
