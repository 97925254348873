/*testimonials*/
import { Dialog } from "@headlessui/react";
import startupsingamarun from "../assets/png/Arun.png.png";
import cuptimePrabakharan from "../assets/png/cuptimePrabakharan.png";

import { AnimatePresence, motion } from "framer-motion";
import Lottie from "lottie-react";
import { ChevronRight } from "lucide-react"
import { ChevronLeft } from "lucide-react"
import React, { useRef } from 'react';

// import Slider from 'react-slick';
// import { ChevronRight, ChevronLeft } from 'lucide-react';
import { Menu, X } from "lucide-react";
import { memo, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import cloudCreditsLottie from "../assets/lottie/cloudCredits.json";
import completeProductLottie from "../assets/lottie/completeProduct.json";
import flexiiPricingLottie from "../assets/lottie/flexiiPricing.json";
import postLaunchLottie from "../assets/lottie/postLaunch.json";
import quickReleaseLottie from "../assets/lottie/quickRelease.json";
import scalableLottie from "../assets/lottie/scalable.json";
import startupExpertiseLottie from "../assets/lottie/startupExpertise.json";
import techAdvisoryLottie from "../assets/lottie/techAdvisory.json";
import Ohanalogo from "../assets/png/Ohanalogo.png";
import Treece from "../assets/png/TREECE_logo.png";
import designrush from "../assets/png/Verified Agency v1.png";
import canadaTower from "../assets/png/canada-tower.png";
import indiaGate from "../assets/png/indiGate.png";
import mapPin from "../assets/png/map-pin.png";
import ukTower from "../assets/png/uk.png";

import cuptime from "../assets/png/cuptimeRS.png";
import footer from "../assets/png/footer.png";
import greenTick from "../assets/png/greenTick.png";
import linkedin from "../assets/png/linkedin.png";
import logo from "../assets/png/logo.png";

import noukhaLongLogo from "../assets/png/noukha-long-logo.png";
import startUpSingam from "../assets/png/startUpSingam.png";
import aiWhite from "../assets/png/tech2/aiWhite.png";
import angularWhite from "../assets/png/tech2/angularWhite.png";
import awsWhite from "../assets/png/tech2/awsWhite.png";
import dockerWhite from "../assets/png/tech2/dockerWhite.png";
import gcpWhite from "../assets/png/tech2/gcpWhite.png";
import gitWhite from "../assets/png/tech2/gitWhite.png";
import jenkinsWhite from "../assets/png/tech2/jenkinsWhite.png";
import kubernetsWhite from "../assets/png/tech2/kubernetsWhite.png";
import mongodbWhite from "../assets/png/tech2/mongodbWhite.png";
import nodeWhite from "../assets/png/tech2/nodeWhite.png";
import pythonWhite from "../assets/png/tech2/pythonWhite.png";
import reactWhite from "../assets/png/tech2/reactWhite.png";
import redisWhite from "../assets/png/tech2/redisWhite.png";
import postgresqlWhite from "../assets/png/tech2/terraformWhite.png";

import { HeroSection } from "../components/HeroSection";
// import OurServices from "../components/OurServices";
import Slider from "react-slick";
// import star from "../assets/png/Journey.png" ;
// import BlogDetails from "./BlogDetails";
// import OurBlogs from "../components/OurBlogs";
// import OurBlog from "./Blogs";
// import MoreStep from "./Blogs";


function WhoWeAreSection2() {
  return (
    <div className="bg-gray-50 pb-16 pt-4">
      <div className="max-w-6xl mx-auto px-1 sm:px-2 lg-p1">
        <div
          id="why-choose-us"
          className="text-center mt-10 justify-center mb-8"
        >
          {/* "Our Services" Label */}
          <div className="inline-block text-3xl font-medium px-4 pt-2 rounded-lg mb-4 ">
            Ratings & Recognition
          </div>
          <div className="flex items-center justify-center">
            <div className="w-10 h-0.5 bg-green-600"></div>
          </div>
        </div>
        <div className="flex flex-col md:flex-row justify-center items-center gap-8">
          {/* Goodfirms Widget */}
          <div className="flex shadow-md border-green-600 rounded-lg w-[270px] bg-white justify-center items-center h-20">
            <div
              className="goodfirm-widget w-full mt-1"
              data-widget-type="goodfirms-widget-t8"
              data-widget-pattern="poweredby-star"
              data-height="80"
              data-company-id="167451"
            ></div>
          </div>
          {/* Clutch Widget */}
          <div className="shadow-md bg-white rounded-lg flex justify-center items-center p-4 w-[270px] h-20 ">
            <div
              className="clutch-widget"
              data-url="https://widget.clutch.co"
              data-widget-type="2"
              data-height="50"
              data-nofollow="true"
              data-expandifr="true"
              data-clutchcompany-id="2466878"
            ></div>
          </div>
          {/* DesignRush Widget */}
          <div className="shadow-md bg-white rounded-lg flex justify-center items-center p-4 w-[270px] h-20">
            <a
              href="https://www.designrush.com/agency/software-development/flutter/trends/what-is-flutter"
              target="_blank"
              rel="noopener noreferrer"
              className="w-full flex justify-center"
            >
              <img
                src={designrush}
                alt="DesignRush"
                className="w-[200px] h-auto object-contain" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
// const CardGrid = () => {
//   const cards = [
//     {
//       title: "React.js / React Native",
//       description: "Building dynamic web and mobile apps with seamless UIs",
//       icon: reactNativeIcon,
//     },
//     {
//       title: "Node.js",
//       description: "Speedy, scalable backends for modern apps",
//       icon: nodejsIcon,
//     },
//     {
//       title: "Python",
//       description: "From web apps to AI, Python's got you covered",
//       icon: pythonIcon,
//     },
//     {
//       title: "Nest.js",
//       description: "Building efficient, scalable server-side applications",
//       icon: nextIcon,
//     },
//     {
//       title: "AWS / Google Cloud",
//       description: "Unleashing the cloud's power for your project",
//       icon: cloudIcon,
//     },
//     {
//       title: "Angular",
//       description: "Dynamic, single-page web applications made easy",
//       icon: angularIcon,
//     },
//     {
//       title: "MongoDB",
//       description: "Flexible, scalable NoSQL databases for modern apps",
//       icon: mongodbIcon,
//     },
//     {
//       title: "PostgreSQL",
//       description: "Powerful, reliable relational databases",
//       icon: postgresqlIcon,
//     },
//     {
//       title: "Redis",
//       description: "Super-fast, in-memory data storage",
//       icon: redis,
//     },
//     {
//       title: "Kubernetes / Docker",
//       description: "Effortless containerization and orchestration",
//       icon: kubernetesIcon,
//     },
//     {
//       title: "DevOps Tools",
//       description: "Streamlining CI/CD with Jenkins, Terraform, and Git",
//       icon: devToolIcon,
//     },
//     {
//       title: "LLMs",
//       description: "Building smarter solutions with advanced AI models",
//       icon: aiIcon,
//     },
//   ];

//   return (
//     <>
//       <div className="text-center mt-10 justify-center mb-8">
//         {/* "Our Services" Label */}
//         <div className="inline-block text-3xl font-medium px-4 pt-2 rounded-lg mb-4">
//           Our Tech
//         </div>
//         <div className="flex items-center justify-center">
//           <div className={`w-10 h-0.5 bg-green-600`}></div>
//         </div>
//       </div>
//       <div className="flex justify-center items-center">
//         <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 px-6">
//           {cards.map((card, index) => (
//             <div
//               key={index}
//               className={`border border-n05 bg-[#43B968] p-6 rounded-lg flex flex-col justify-center items-center shadow-md text-center transition-transform transform hover:scale-105 hover:shadow-xl`}
//             >
//               <img
//                 src={card?.icon}
//                 alt="Illustration"
//                 className="w-1/6 md:w-1/6 mb-4"
//               />
//               <h3 className="text-xl font-semibold text-white mb-2">
//                 {card.title}
//               </h3>
//               <p className="text-white text-sm">{card.description}</p>
//             </div>
//           ))}
//         </div>
//       </div>
//     </>
//   );
// };

const OurClientsV2 = () => {
  const cardData = [
    {
      title: "Cup Time",
      weburl:"https://www.cuptime.in/",
      description: "Cup Time is a Tech-driven B2B beverage brand focused on delivering freshly brewed tea and coffee to businesses.",
      image: cuptime,
      linkedinUrl: "https://www.linkedin.com/company/cup-time/",
      country: "TamilNadu, India"
    },
    {
      title: "Startup Singam",
      weburl: "https://www.startupsingam.com/",
      description: "Tamil Nadu's first Business Reality Show on TV encouraging entrepreneurship and investment in Startups and SMEs",
      image: startUpSingam, linkedinUrl: "https://www.linkedin.com/company/startupsingam/",
      country: "TamilNadu, India"
    },
    {
      title: "Ohana",
      weburl:"https://www.pickohana.com/",
      description: "Ohana is a restaurant management software that specializes in creating custom websites for restaurants while streamlining order management, POS systems, and inventory management",
      image: Ohanalogo,
      linkedinUrl: "https://www.linkedin.com/company/ohanatech/",
      country: "Tennessee, USA"
    },
    {
      title: "Treece",
      weburl: "https://www.treece-se.com/",
      description: "Treece is a professional services firm offering a full suite of structural engineering services for the construction industry. The firm’s specialties include Structural Engineering, Construction Support, and Discovery & Forensics.",
      image: Treece,
      linkedinUrl: "https://www.linkedin.com/company/treece/",
      country: "Wisconsin, USA"
    }
  ];

  const settings = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay:true,
    autoplaySpeed: 2000,
    arrows: true,
    nextArrow: <ChevronRight />,
    prevArrow: <ChevronLeft />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  const sliderRef = useRef(null);

  const handlePrev = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  const handleNext = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };


  return (
    <section id="clients" className="py-12 bg-gray-100 overflow-x-hidden">
       <div className="relative max-w-6xl mx-auto text-center">
           <h2 className="text-3xl font-medium px-4 pt-2 rounded-lg gap-6 mb-4">Our Valued Clients</h2>
            <div className="flex items-center justify-center">
              <div className="w-10 h-0.5 bg-green-600"></div>
            </div>
       <Slider {...settings} ref={sliderRef} className="mt-20">
          {cardData.map((card, index) => (
            <div key={index}
              className="slick-slide mr-5 rounded-2xl p-1 relative border-10 border-teal-100 h-[390px] mb-6"
              style={{
                borderTopLeftRadius: "2rem",
                borderBottomRightRadius: "2rem",
              }}>
              <div className="slick-slide mr-5 absolute bg-[#14A842] w-16 h-16 top-0 right-0 transform translate-x-0 -translate-y-0 rounded"></div>
              <div className="slick-slide mr-5 absolute bg-[#14A842] w-16 h-16 bottom-0 left-0 transform -translate-x-0 translate-y-0 rounded"></div>
              <div className=" slick-slide mr-5 w-90 items-left text-left h-full bg-white rounded p-6 relative border-10 border-teal-100"
                style={{
                  borderTopLeftRadius: "2rem",
                  borderBottomRightRadius: "2rem",
                }}>
                <a href={card.linkedinUrl}
                  aria-label="LinkedIn"
                  className="hover:text-gray-700"
                  target="_blank"
                  rel="noopener noreferrer">
                  <div className="absolute mt-8 w-16 h-16 top-0 right-0 transform -translate-x-0 translate-y-0 rounded">
                    {card.linkedinUrl && <img src={linkedin} alt="LinkedIn Logo" className="w-8" />}
                  </div>
                </a>
                <div className="flex mb-4 items-center">
                  <img src={card.image || "/default-image.jpg"}
                    alt={card.title} className="w-20 h-auto max-w-[6rem]" />
                </div>
                <h3 className="text-lg font-bold hover:underline flex items-center mt-5">
                  <a
                    href={card.weburl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center"
                    aria-label="website"
                  >
                    {card.title}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      id="Openlink"
                      className="ml-2 mt-0.5 w-3 h-3 flex items-center justify-center"
                    >
                      <path
                        fill="#13a842"
                        fillRule="evenodd"
                        d="M13,1 L13,4 L17.879,4 L7.94,13.939 L10.061,16.06 L20,6.121 L20,11 L23,11 L23,1 L13,1 Z M1,1 L1,23 L23,23 L23,14.999 L20,14.999 L20,20 L4,20 L4,4 L9,4 L9,1 L1,1 Z"
                        className="color5b5b5b svgShape"
                      />
                    </svg>
                  </a>
                </h3>
                <p className="text-gray-600 text-sm font-medium mb-4">{card.country}</p>
                <p className="text-gray-600">{card.description}</p>
              </div>
            </div>
          ))}
       </Slider>
            <button
              onClick={handlePrev}
              className="relative top-5 transform -translate-y-1/2 p-2 hover:bg-gray-100 rounded-full shadow-lg"
            >
              <ChevronLeft className="w-6 h-6 text-[#14A842]"/>
            </button>
            <button
              onClick={handleNext}
              className="relative top-5 transform -translate-y-1/2 p-2 hover:bg-gray-100 rounded-full shadow-lg"
            >
              <ChevronRight className="w-6 h-6 text-[#14A842]" />
            </button>
        </div>
    </section>
  );
};

/*const OurClients = () => {
  const [showMore, setShowMore] = useState(false);

  const handleReadMore = () => {
    setShowMore(!showMore);
  };

  return (
    <>
      <section
        id="our-work"
        className="text-center justify-center bg-[#14A842] pt-8"
      >
        <div className="inline-block text-3xl font-medium px-4 pt-2 rounded-lg mb-4 text-black">
          Testimonial
        </div>
        <div className="flex items-center justify-center">
          <div className="w-10 h-0.5 bg-black"></div>
        </div>
      </section>

      <section className="bg-[#14A842] text-black px-12 sm:px-20 py-8">
        <Swiper
          modules={[Navigation, Pagination, Autoplay]}
          spaceBetween={30}
          slidesPerView={1}
          navigation
          pagination={{ clickable: true }}
          autoplay={{ delay: 4000 }}
        >
          <SwiperSlide>
            <div className="flex flex-col lg:flex-row items-start p-3">
              <div className="sm:w-34 mr-6 mb-6 bg-white rounded-full px-2.5 py-3">
                <img
                  src={cuptime || "/default-logo.png"}
                  alt="logo"
                  className="w-20"
                />
              </div>
              <div className="flex-1 text-left text-black">
                <h1 className="text-4xl text-black">Cup Time</h1>
                <h2 className="text-xl mb-4 text-black">
                  Brewing Freshness, Delivering Energy
                </h2>
                <p className="text-black sm:text-left">
                  At Cup Time, we deliver freshly brewed filter coffee and tea
                  straight to your workplace. Crafted at our dedicated facility,
                  every cup is fresh, flavorful, and perfect for boosting
                  productivity. Whether it’s a morning start or a midday break,
                  Cup Time ensures the perfect cup, every time.
                </p>
                <p className="italic text-white font-medium mt-4 text-left">
                  "Team Noukha did an exceptional job building my MVP,
                  delivering it quickly and providing excellent support
                  throughout. Impressed by their expertise and efficiency, I
                  entrusted them with developing the full product, including
                  mobile apps (Delivery and Customer) and a web app. They
                  understood my vision effortlessly and turned it into the
                  perfect startup product. I highly recommend their team for
                  their dedication and startup expertise."
                </p>
                <a
                  href="https://www.linkedin.com/in/prabaharanpv"
                  className="mt-4 flex items-center"
                >
                  <img
                    src={cuptimePrabakharan}
                    alt="Prabaharan Venugopal"
                    className="w-12 bg-white rounded-full mr-4"
                  />
                  <div>
                    <h3 className="font-bold">Prabaharan Venugopal</h3>
                    <p className="text-sm">Founder, Cup Time</p>
                  </div>
                </a>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="flex flex-col lg:flex-row items-start p-6">
              <div className="sm:w-34 mr-6 mb-6 bg-white rounded-full px-2.5 py-3">
                <img
                  src={startUpSingam || "/default-logo.png"}
                  alt="logo"
                  className="w-10"
                />
              </div>
              <div className="flex-1 text-left text-black">
                <h1 className="text-4xl text-black">Startup Singam</h1>
                <h2 className="text-xl mb-4 text-black">
                  Empowering Entrepreneurs, Igniting Growth
                </h2>
                <p className="text-black sm:text-left">
                  At Startup Singam, we provide aspiring entrepreneurs with the
                  insights, strategies, and inspiration they need to build and
                  scale their businesses. Startup Singam is your ultimate guide
                  to success.
                </p>
                <p
                  className={`italic text-white font-medium ${
                    !showMore ? "line-clamp-3" : ""
                  } text-left`}
                >
                  "We had to build a website from scratch within a tight
                  deadline, with only a basic outline of what we needed. The
                  team at Noukha approached the challenge with great enthusiasm,
                  ensuring they thoroughly understood our requirements while
                  suggesting the best possible solutions. Given that this was a
                  new concept with entirely unique requirements, Ram and his
                  team demonstrated an adventurous spirit by taking on the
                  project. The biggest challenge was the extremely short
                  timeframe, yet they successfully delivered a fully functional
                  website, integrating at least three API call-outs and multiple
                  form integrations. Kudos to the entire Noukha team for an
                  exceptionally well-executed project! The design, user flow,
                  and overall execution were seamless, allowing us to launch
                  without any hiccups. I highly recommend them for any complex
                  and time-sensitive web development projects!"
                </p>
                <button
                  onClick={handleReadMore}
                  className="text-white mt-4 hover:underline"
                >
                  {showMore ? "Read Less" : "Read More"}
                </button>
                <a
                  href="https://www.linkedin.com/in/an2512"
                  className="mt-4 flex items-center"
                >
                  <img
                    src={startupsingamarun}
                    alt="Arun Nair"
                    className="w-12 bg-white rounded-full mr-4"
                  />
                  <div>
                    <h3 className="font-bold">Arun Nair</h3>
                    <p className="text-sm">CEO, Startup Singam</p>
                  </div>
                  <img
                    src={linkedin}
                    alt="LinkedIn Logo"
                    className="w-8 ml-4"
                  />
                </a>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </section>
    </>
  );
};*/

const PricingCards = ({ isIndianIP, handleScroll }) => {
  const cards = [
    {
      title: "Design Implementation",
      price: "Flexible Pricing",
      subtitle: "",
      description:
        "Seamlessly transform your designs into functional and responsive applications with integrated business logic.",
      features: [
        "One mobile app built with React Native or Flutter",
        "One web app developed using React or Angular",
        "25-35 screens, including basic business logic and API integration",
        "Comprehensive API integration",
        "Basic performance optimization for smooth functionality",
        "Deployment for both Android and iOS platforms",
        "Responsive design implementation for optimal user experience across devices",
        "Secure user authentication",
        "Payment gateway integration",
      ],
      buttonText: "Bring Designs to Life",
    },

    {
      title: "Cloud Solutions",
      price: "Flexible Pricing",
      subtitle: "Between",
      description:
        "Scalable and efficient cloud solutions tailored to your project needs.",
      features: [
        "CI/CD pipeline setup (e.g., Jenkins) for 5 microservices, 1 web app, 1 website, and 1 mobile app",
        "Provision of two environments: development and production",
        "Support for any major cloud platform (AWS, Google Cloud, Azure, etc.)",
        "Kubernetes and Docker configuration (if applicable)",
        "Assistance with obtaining and managing cloud credits",
        "Load balancer setup for enhanced performance and reliability",
        "Cloud security measures (e.g., AWS WAF or equivalent)",
        "Domain configuration with providers like GoDaddy, Cloudflare, etc.",
      ],
      buttonText: "Scale with the Cloud",
    },
    {
      title: "MVP Combo",
      price: "Flexible Pricing",
      subtitle: "",
      description:
        "Accelerate your product launch with a cost-effective, feature-rich MVP tailored to your business needs.",
      features: [
        "One mobile app built with React Native",
        "One web app developed using React or Angular",
        "20-25 screens (combined total for mobile and web apps)",
        "Basic cloud services implementation, including CI/CD pipeline setup for smooth deployment",
        "Support for development and production environments",
        "Kubernetes and Docker configuration for scalable and efficient architecture",
        "Secure user authentication (OTP or JWT token-based)",
        "MongoDB database implementation",
        "Basic performance optimization for enhanced user experience",
        "Deployment for both Android and iOS platforms",
        "Domain configuration with providers like GoDaddy or Cloudflare",
        "Load balancer setup for improved app performance and reliability",
        "Payment gateway integration (Razorpay or similar)",
        "Microservices integration for modular and scalable app functionality",
      ],
      buttonText: "Launch Your MVP",
      isPopular: true,
    },
    {
      title: "Backend Implementation",
      price: "Flexible Pricing",
      subtitle: "",
      description:
        "Build a robust and secure backend with Node.js, integrated with cloud solutions for scalable and efficient performance.",
      features: [
        "Backend API development using Node.js with Express or NestJS",
        "Comprehensive API design and implementation",
        "Secure user authentication (OAuth, OTP, or JWT token-based)",
        "MongoDB or any relational database integration",
        "CI/CD pipeline setup (Jenkins, AWS CodePipeline, or equivalent)",
        "Kubernetes and Docker configuration for containerized and scalable microservices",
        "Cloud support for deployment on platforms like AWS, Google Cloud, or Azure",
        "Provision of development and production environments",
        "Load balancer setup for enhanced reliability and performance",
        "Cloud security measures (e.g., AWS WAF or equivalent)",
        "Assistance with obtaining and managing cloud credits",
        "Domain and DNS configuration with providers like GoDaddy or Cloudflare",
      ],
      buttonText: "Build Your Backend",
    },
    {
      title: "Mobile and Web App Development",
      price: "Flexible Pricing",
      subtitle: "Between",
      description:
        "Build high-quality mobile and web applications tailored to your needs.",
      features: [
        "Two mobile apps using React Native or Flutter",
        "One web app built with React or Angular",
        "10-15 screens (inclusive of web and mobile apps, depending on features)",
        "Microservices and web app pipeline setup (AWS, Jenkins, or Devtron)",
        "iOS and Android apps with Play Store/App Store deployment",
        "Google Analytics integration for user insights",
        "Basic payment gateway integration (Razorpay or similar)",
        "MERN or MEAN stack development",
        "Secure user authentication (OTP or JWT token-based)",
        "MongoDB database implementation",
        "Basic performance optimization",
        "Firebase implementation",
        "Scalable architecture with Kubernetes, Docker, and load balancers for microservices",
      ],
      buttonText: "Start Your App Journey",
    },
    {
      title: "Long-Term Partnership",
      price: "Flexible Pricing",
      subtitle: "",
      description:
        "Partner with us for continuous development, scaling, and support of your tech products. Our dedicated team ensures seamless collaboration and ongoing innovation to meet your evolving business needs.",
      features: [
        "Mobile and web app development",
        "Backend development with microservices architecture",
        "Secure authentication and authorization",
        "Third-party API integration",
        "Payment and analytics integration",
        "Robust security measures and scalable cloud solutions (AWS, GCP, Azure, and more)",
        "CI/CD pipeline setup for efficient deployments",
        "Regular sprint planning and feature delivery",
        "Performance optimization and scalability enhancements",
        "Ongoing maintenance and support",
      ],
      buttonText: "Contact Us for Your Solution",
    },
  ];

  return (
    <>
      <section id="pricing" className="text-center mt-10 justify-center">
        <div className="inline-block text-3xl font-medium px-4 pt-2 rounded-lg mb-4">
          What We Offer
        </div>
        <div className="flex items-center justify-center">
          <div className={`w-10 h-0.5 bg-green-600`}></div>
        </div>
      </section>
      <div className="bg-white py-10">
        <div className="container mx-auto px-4 grid gap-8 md:grid-cols-2 lg:grid-cols-3 property">
          {cards.map((card, index) => (
            <div
              key={index}
              className={`flex flex-col justify-between relative bg-white border border-gray-200 rounded-lg shadow-md p-6 transition-all duration-300 hover:shadow-xl  ${card.isPopular ? "ring-2 ring-green-500" : ""
                }`}
            >
              {card.isPopular && (
                <div className="text-center absolute -top-3 -right-3 bg-green-500 text-white text-xs font-semibold px-2 py-1 rounded-full">
                  Top Pick
                </div>
              )}
              <h2 className="text-center text-lg font-semibold text-gray-800">
                {card.title}
              </h2>
              {!isIndianIP ? null : (
                <p className="text-center text-4xl font-bold text-green-500">
                  {card.price}
                </p>
              )}
              <p className="text-center text-gray-600 mt-2">
                {card.description}
              </p>
              <ul className="mt-4 space-y-2 flex-1">
                {card.features.map((feature, i) => (
                  <li key={i} className="flex items-center space-x-2">
                    <img
                      src={greenTick} // Replace with your logo path
                      alt="Nouka Logo"
                      className="w-4"
                    />
                    <span className="text-gray-700 text-sm">{feature}</span>
                  </li>
                ))}
              </ul>
              {isIndianIP ? (
                <button
                  data-tally-open="wMr5Ek"
                  data-tally-layout="modal"
                  data-tally-width="800"
                  data-tally-auto-close="2000"
                  style={{
                    padding: "10px 20px",
                    fontSize: "16px",
                    cursor: "pointer",
                  }}
                  className={`mt-6 w-full py-2 px-4 rounded-md text-white font-semibold ${card.isPopular
                      ? "bg-green-500 hover:bg-green-600"
                      : "bg-gray-800 hover:bg-gray-900"
                    }`}
                >
                  {card.buttonText}
                </button>
              ) : (
                <button
                  data-tally-open="wMr5Ek"
                  data-tally-layout="modal"
                  data-tally-width="800"
                  data-tally-auto-close="2000"
                  style={{
                    padding: "10px 20px",
                    fontSize: "16px",
                    cursor: "pointer",
                  }}
                  className={`mt-6 w-full py-2 px-4 rounded-md text-white font-semibold ${card.isPopular
                      ? "bg-green-500 hover:bg-green-600"
                      : "bg-gray-800 hover:bg-gray-900"
                    }`}
                >
                  {card.buttonText}
                </button>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
const Footer = ({ isIndianIP, handleScroll }) => {
  return (
    <footer
      className="bg-darkGreen text-white py-10 relative bg-cover bg-center"
      style={{ backgroundImage: `url(${footer})` }}
    >
      {/* GoodFirms Script */}
      <script
        type="text/javascript"
        src="https://assets.goodfirms.co/assets/js/widget.min.js"
        async
      ></script>
      <script
        type="text/javascript"
        src="https://widget.clutch.co/static/js/widget.js"
      ></script>{" "}
      <div className="text-center p-20 sm:p-6 pb-6 sm:pb-8 relative">
        <div className="flex justify-center mb-4">
          <img src={logo} alt="Noukha Logo" className="w-10 sm:w-32 md:w-40" />
        </div>
        <h2 className="text-xl sm:text-2xl md:text-3xl font-bold mb-2 relative z-10 text-[#13A842]">
          Custom Tech & AI Solutions
        </h2>
        <p className="text-xs sm:text-sm md:text-base mb-4 relative z-10 text-[#13A842]">
          You Name It We Develop It | Your Trusted Tech Partner
        </p>
        {isIndianIP ? (
          <button
            data-tally-open="wMr5Ek"
            data-tally-layout="modal"
            data-tally-width="800"
            data-tally-auto-close="2000"
            style={{
              padding: "10px 20px",
              fontSize: "16px",
              cursor: "pointer",
            }}
            className="bg-black text-white px-4 py-1 sm:px-5 sm:py-2 md:px-6 md:py-3 mt-4 rounded-full hover:bg-gray-800 transition duration-300 ease-in-out relative z-10 text-xs sm:text-sm md:text-base"
          >
            Start Your Project
          </button>
        ) : (
          <button
            data-tally-open="wMr5Ek"
            data-tally-layout="modal"
            data-tally-width="800"
            data-tally-auto-close="2000"
            style={{
              padding: "10px 20px",
              fontSize: "16px",
              cursor: "pointer",
            }}
            className="bg-black text-white px-4 py-1 sm:px-5 sm:py-2 md:px-6 md:py-3 mt-4 rounded-full hover:bg-gray-800 transition duration-300 ease-in-out relative z-10 text-xs sm:text-sm md:text-base"
          >
            Start Your Project
          </button>
        )}

        {/* Icon/Image Divs */}
        {/* Icon/Image Divs */}
        <div
          className="absolute flex items-center justify-center"
          style={{
            top: "40%",
            left: "20%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <img
            src={awsWhite}
            alt="React.js / React Native"
            className="w-6 sm:w-8 md:w-10 mr-2"
          />
        </div>
        <div
          className="absolute flex items-center justify-center"
          style={{
            top: "10%",
            left: "30%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <img
            src={gcpWhite}
            alt="Node.js"
            className="w-6 sm:w-8 md:w-10 mr-2"
          />
        </div>
        <div
          className="absolute flex items-center justify-center"
          style={{
            top: "18%",
            left: "95%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <img
            src={reactWhite}
            alt="Python"
            className="w-6 sm:w-8 md:w-10 mr-2"
          />
        </div>

        <div
          className="absolute flex items-center justify-center"
          style={{
            top: "13%",
            left: "83%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <img src={aiWhite} alt="Python" className="w-6 sm:w-8 md:w-10 mr-2" />
        </div>
        <div
          className="absolute flex items-center justify-center"
          style={{
            top: "10%",
            left: "70%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <img
            src={nodeWhite}
            alt="Nest.js"
            className="w-16 sm:w-20 md:w-24 mr-2"
          />
        </div>
        <div
          className="absolute flex items-center justify-center"
          style={{
            top: "60%",
            left: "13%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <img
            src={pythonWhite}
            alt="AWS / Google Cloud"
            className="w-6 sm:w-8 md:w-10 mr-2"
          />
        </div>
        <div
          className="absolute flex items-center justify-center"
          style={{
            top: "18%",
            left: "15%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <img
            src={gitWhite}
            alt="AWS / Google Cloud"
            className="w-6 sm:w-8 md:w-14 mr-2"
          />
        </div>
        <div
          className="absolute flex items-center justify-center"
          style={{
            top: "85%",
            left: "5%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <img
            src={jenkinsWhite}
            alt="AWS / Google Cloud"
            className="w-4 sm:w-6 md:w-10 mr-2 rounded"
          />
        </div>
        <div
          className="absolute flex items-center justify-center"
          style={{
            top: "20%",
            left: "5%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <img
            src={kubernetsWhite}
            alt="AWS / Google Cloud"
            className="w-6 sm:w-8 md:w-10 mr-2"
          />
        </div>
        <div
          className="absolute flex items-center justify-center"
          style={{
            top: "85%",
            left: "23%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <img
            src={angularWhite}
            alt="Angular"
            className="w-6 sm:w-8 md:w-10 mr-2"
          />
        </div>
        <div
          className="absolute flex items-center justify-center"
          style={{
            top: "65%",
            left: "95%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <img
            src={mongodbWhite}
            alt="MongoDB"
            className="w-6 sm:w-12 md:w-14 mr-2"
          />
        </div>
        <div
          className="absolute flex items-center justify-center"
          style={{
            top: "85%",
            left: "88%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <img
            src={postgresqlWhite}
            alt="MongoDB"
            className="w-8 sm:w-10 md:w-12 mr-2"
          />
        </div>
        <div
          className="absolute flex items-center justify-center"
          style={{
            top: "78%",
            left: "78%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <img
            src={dockerWhite}
            alt="MongoDB"
            className="w-8 sm:w-10 md:w-12 mr-2"
          />
        </div>
        <div
          className="absolute flex items-center justify-center"
          style={{
            top: "40%",
            left: "85%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <img
            src={redisWhite}
            alt="MongoDB"
            className="w-6 sm:w-8 md:w-10 mr-2"
          />
        </div>
        {/* [Other icon images remain the same here] */}
      </div>
      <div className="border-t border-gray-700 mt-10 pt-4 text-center text-xs md:text-sm ">
        <div className="flex flex-col sm:flex-row justify-between items-center px-4 py-2 text-gray-500 text-sm">
          <div className="flex items-center justify-center sm:justify-start sm:w-1/3 order-last sm:order-first mt-4 sm:mt-0">
            <a
              href="https://www.linkedin.com/company/noukha/"
              aria-label="LinkedIn"
              className="hover:text-gray-700 mt-1"
            >
              <img
                src={linkedin} // Replace with your logo path
                alt="LinkedIn"
                className="w-5"
              />
            </a>
            <a href="mailto:hello@noukha.in" className="text-white font-medium">
              <span className="ml-1 mr-1">|</span>
              hello@noukha.in
            </a>
          </div>
          <p className="text-white order-first sm:order-none">
            All Rights Reserved © 2025 | Noukha Technologies
          </p>
          <div className="w-1/3 hidden sm:block"></div>
        </div>
      </div>
    </footer>
  );
};

const WhoWeAreSection = () => {
  const services = [
    {
      icon: quickReleaseLottie,
      title: "Quick Release Timelines",
      description:
        "In today’s quick commerce era, speed is everything. We deliver MVPs in weeks, not months, helping you launch swiftly and gain a competitive edge.",
    },
    {
      icon: scalableLottie,
      title: "Scalable Architecture with Cloud Solutions",
      description:
        "We build scalable, resilient applications with Docker and Kubernetes, ensuring efficient deployment and resource management.",
    },
    {
      icon: flexiiPricingLottie,
      title: "Flexible Pricing Model",
      description:
        "Our pricing is designed to fit your budget, offering flexible options to ensure you get the best value for your investment.",
    },
    {
      icon: completeProductLottie,
      title: "Complete Product Development",
      description:
        "We specialize in building MVPs and scaling complete products tailored to your needs. Using agile methodology, we ensure flexibility, efficiency, and seamless product evolution.",
    },
    {
      icon: techAdvisoryLottie,
      title: "Tech Advisory",
      description:
        "Leverage our expertise as trusted tech advisors to help you make informed decisions at every stage of your project.",
    },
    {
      icon: startupExpertiseLottie,
      title: "Startup Expertise",
      description:
        "Our team specializes in guiding startups from idea to scale, addressing unique challenges to ensure your success.",
    },
    {
      icon: postLaunchLottie,
      title: "Post-Launch Support Available",
      description:
        "If needed, we’re ready to provide support for 3 to 6 months, even for MVPs, to ensure a smooth transition and early success for your product.",
    },
    {
      icon: cloudCreditsLottie,
      title: "Cloud Credits Assistance",
      description:
        "We help you secure cloud credits from AWS, Google Cloud, and Azure, reducing your initial infrastructure costs and accelerating your startup journey.",
    },
  ];

  return (
    <div className="bg-gray-50 pb-16 pt-4">
      <div className="max-w-6xl mx-auto px-1 sm:px-2 lg-p1">
        <div
          id="why-choose-us"
          className="text-center mt-10 justify-center  mb-8"
        >
          {/* "Our Services" Label */}
          <div className="inline-block text-3xl font-medium px-4 pt-2 rounded-lg mb-4">
            Why Choose Us?
          </div>
          <div className="flex items-center justify-center">
            <div className={`w-10 h-0.5 bg-green-600`}></div>
          </div>
        </div>
        <div className="grid grid-rows-2 gap-y-8 justify-items-center">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 justify-items-center">
            {services
              .slice(0, Math.ceil(services.length / 2))
              .map((service, index) => (
                <div
                  key={index}
                  className="bg-white shadow-lg rounded-xl px-6 pb-4 hover:scale-105 transition-transform duration-300 max-w-xs flex flex-col items-center text-center"
                >
                  <Lottie
                    animationData={service.icon}
                    loop={true}
                    className="w-48"
                  />
                  <h3 className="text-lg font-semibold mb-4  h-12 text-black">
                    {service.title}
                  </h3>
                  <p className="text-gray-600 text-sm">{service.description}</p>
                </div>
              ))}
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 justify-items-center">
            {services
              .slice(Math.ceil(services.length / 2))
              .map((service, index) => (
                <div
                  key={index}
                  className="bg-white shadow-lg rounded-xl px-6 pb-4 hover:scale-105 transition-transform duration-300 max-w-xs flex flex-col items-center text-center"
                >
                  <Lottie
                    animationData={service.icon}
                    loop={true}
                    className="w-48"
                  />
                  <h3 className="text-lg font-semibold mb-4  h-12 text-black">
                    {service.title}
                  </h3>
                  <p className="text-gray-600 text-sm">{service.description}</p>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};
const Header = ({ handleScroll }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleOpenCalendly = () => {
    if (window.Calendly) {
      window.Calendly.initPopupWidget({
        url: "https://calendly.com/noukha/30mins?hide_landing_page_details=1&hide_gdpr_banner=1",
      });
    }
  };

  // Close menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isMenuOpen && !event.target.closest(".mobile-menu")) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [isMenuOpen]);

  return (
    <header className="fixed z-50 top-0 left-0 w-full flex justify-between items-center px-8 py-6 bg-white md:shadow h-24 md:h-28">
      {/* Logo */}      <img src={noukhaLongLogo} alt="Nouka Logo" className="h-12 sm:h-18" />
      {/* Desktop Navigation */}
      <nav className="hidden md:flex items-center space-x-8 text-lg">
        {[
          { label: "Why Choose Us?", id: "why-choose-us" },
          { label: "Our Clients", id: "clients" },
          { label: "Testimonial", id: "our-work" },
          { label: "What We Offer", id: "pricing" },
        ].map((item) => (
          <a
            key={item.id}
            href={`#${item.id}`}
            onClick={(e) => handleScroll(e, item.id)}
            className="text-gray-700 font-semibold hover:text-green-600"
          >
            {item.label}
          </a>
        ))}
        <button
          onClick={handleOpenCalendly}
          className="font-semibold text-green-600"
        >
          Talk to Our Experts
        </button>
      </nav>

      {/* Mobile Menu Button */}
      <button
        className="inline-flex items-end justify-center overflow-x:hidden gap-2 rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-neutral-950 disabled:pointer-events-none disabled:opacity-50 hover:bg-neutral-100 hover:text-neutral-900 h-9 w-9 md:hidden"
        onClick={() => setIsMenuOpen(true)}
      >
        <Menu className="h-6 w-6 text-gray-800" />
      </button>

      {/* Mobile Menu */}
      <AnimatePresence>
        {isMenuOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 z-40" /> /* Overlay to detect outside clicks */
        )}
        {isMenuOpen && (
          <motion.div
            initial={{ y: "100%" }}
            animate={{ y: 0 }}
            exit={{ y: "100%" }}
            transition={{ type: "tween", duration: 0.3 }}
            className="fixed bottom-0 left-0 w-full bg-white shadow-lg p-8 rounded-t-2xl z-50 flex flex-col items-center mobile-menu overflow-x:hidden h-3/4 pb-6"
          >
            {/* Close Button */}
            <button
              onClick={() => setIsMenuOpen(false)}
              className="absolute top-6 right-6 text-gray-800"
            >
              <X className="h-6 w-6" />
            </button>

            <nav className="mt-10 space-y-6 w-full max-w-md text-center text-2g">
              {[
                { label: "Why Choose Us?", id: "why-choose-us" },
                { label: "Our Clients", id: "clients" },
                { label: "Testimonial", id: "our-work" },
                { label: "What We Offer", id: "pricing" },
              ].map((item) => (
                <a
                  key={item.id}
                  href={`#${item.id}`}
                  onClick={(e) => {
                    handleScroll(e, item.id);
                    setIsMenuOpen(false);
                  }}
                  className="block text-gray-700 font-semibold py-3 hover:text-green-600"
                >
                  {item.label}
                </a>
              ))}
              <button
                onClick={() => {
                  handleOpenCalendly();
                  setIsMenuOpen(false);
                }}
                className="w-full text-green-600 font-semibold py-3"
              >
                Talk to Our Experts
              </button>
            </nav>
          </motion.div>
        )}
      </AnimatePresence>
    </header>
  );
};

// const ContactForm = () => {
//   const [isSubmitting, setIsSubmitting] = useState(false);

//   const handleSubmit = (event) => {
//     event.preventDefault();
//     setIsSubmitting(true);

//     const formData = new FormData(event.target);

//     fetch(
//       "https://script.google.com/macros/s/AKfycbx3hKzXdP7woYrG78MZLknNSqmmZldiQDbGP0MJ1Ii_sPAHNOnCkmM-MufnvQPa2JdO/exec",
//       {
//         method: "POST",
//         body: formData,
//       }
//     )
//       .then((response) => response.text())
//       .then((data) => {
//         alert("Form submitted successfully!");
//         event.target.reset(); // Reset the form after successful submission
//       })
//       .catch((error) => {
//         alert("Error submitting form: " + error);
//       })
//       .finally(() => setIsSubmitting(false));
//   };

//   return (
//     <div className="bg-gray-100 pb-16 pt-4">
//       <div className="max-w-6xl mx-auto px-1 sm:px-2 lg:px-4">
//         <div
//           id="why-choose-us"
//           className="text-center mt-10 justify-center mb-8"
//         >
//           {/* "Our Services" Label */}
//           <div className="inline-block text-3xl font-medium px-4 pt-2 rounded-lg mb-4">
//             Contact Us
//           </div>
//           <div className="flex items-center justify-center">
//             <div className="w-10 h-0.5 bg-green-600"></div>
//           </div>
//         </div>
//         <div className="flex flex-col md:flex-row items-center px-1 py-2">
//           {/* Left Side */}
//           <div className="w-full md:w-1/2 flex flex-col items-center mb-10 md:mb-0 mr-10">
//             <div className=" flex justify-center items-center">
//               <Lottie
//                 animationData={contactUsLottie}
//                 loop={true}
//                 className="w-4/6 "
//               />
//             </div>
//             {/* <h1 className="text-3xl font-medium mb-4 text-center">
//               Hello @ Noukha
//             </h1> */}
//             <a
//               href="mailto:hello@noukha.in"
//               className="mt-10 bg-green-600 text-white font-medium px-6 py-3 rounded-lg shadow-lg hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-offset-2 transition duration-300 mb-6"
//             >
//               Mail Us: hello@noukha.in
//             </a>
//           </div>

//           {/* Right Side */}
//           <form
//             id="contactForm"
//             className="w-full md:w-1/2 bg-white p-8 rounded-lg shadow-lg"
//             onSubmit={handleSubmit}
//           >
//             {/** Name Field */}
//             <div className="relative mb-6">
//               <input
//                 type="text"
//                 id="name"
//                 name="name"
//                 required
//                 className="peer w-full px-4 pt-6 pb-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500 placeholder-transparent"
//                 placeholder="Name"
//               />
//               <label
//                 htmlFor="name"
//                 className="absolute left-4 top-2 text-gray-500 text-sm transition-all peer-placeholder-shown:top-4 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-focus:top-2 peer-focus:text-sm peer-focus:text-green-500"
//               >
//                 Name
//               </label>
//             </div>

//             {/** Email Field */}
//             <div className="relative mb-6">
//               <input
//                 type="email"
//                 id="email"
//                 name="email"
//                 required
//                 className="peer w-full px-4 pt-6 pb-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500 placeholder-transparent"
//                 placeholder="Email"
//               />
//               <label
//                 htmlFor="email"
//                 className="absolute left-4 top-2 text-gray-500 text-sm transition-all peer-placeholder-shown:top-4 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-focus:top-2 peer-focus:text-sm peer-focus:text-green-500"
//               >
//                 Email
//               </label>
//             </div>

//             {/** Request Description */}
//             <div className="relative mb-6">
//               <textarea
//                 id="description"
//                 name="description"
//                 required
//                 rows="4"
//                 className="peer w-full px-4 pt-6 pb-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500 placeholder-transparent"
//                 placeholder="Request Description"
//               ></textarea>
//               <label
//                 htmlFor="description"
//                 className="absolute left-4 top-2 text-gray-500 text-sm transition-all peer-placeholder-shown:top-4 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-focus:top-2 peer-focus:text-sm peer-focus:text-green-500"
//               >
//                 Request Description
//               </label>
//             </div>

//             {/** Submit Button */}
//             <button
//               type="submit"
//               disabled={isSubmitting}
//               className={`w-full py-2 px-4 rounded-lg text-white font-medium transition-colors duration-300 ${
//                 isSubmitting
//                   ? "bg-gray-400 cursor-not-allowed"
//                   : "bg-green-500 hover:bg-green-600"
//               } focus:outline-none focus:ring-2 focus:ring-green-500`}
//             >
//               {isSubmitting ? "Submitting..." : "Submit"}
//             </button>
//           </form>
//         </div>
//       </div>
//     </div>
//   );
// };
const OfficeAddresses = () => {
  const addresses = [
    {
      country: "India",
      addressLines: [
        "No 4, First floor, 2nd Street, CS Nagar, Vadavalli, Coimbatore - 641025",
      ],
      email: "hello@noukha.in",
      image: indiaGate,
      className: "w-32 -mb-4 md:-mb-8",
    },
    {
      country: "Canada",
      addressLines: [
        "3077 Weston Rd",
        "North York",
        "Greater Toronto Area",
        "Canada - M9M 3A1",
      ],
      email: "hello@noukha.in",
      image: canadaTower,
      className: "w-28 mt-6",
    },
    {
      country: "United Kingdom",
      addressLines: ["29, Pumphouse Crescent", "Watford, WD17 2AA"],
      email: "hello@noukha.in",
      image: ukTower, // Ensure you have imported/defined the appropriate image variable
      className: "w-24 mt-8",
    },
  ];

  const MapPinIcon = () => (
    <img src={mapPin} alt="Pin" className="w-8 h-6 mt-6 object-contain" />
  );

  const MailIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      id="email"
      className="w-6 h-6 mr-3"
    >
      <path
        fill="#13A842"
        d="M21.75 6v12a1.6 1.6 0 0 1-.11.59l-6.58-7.31 6.59-5.86a1.9 1.9 0 0 1 .1.58M2.36 5.42a1.7 1.7 0 0 0-.11.58v12a1.6 1.6 0 0 0 .11.59l6.58-7.31ZM13.16 13a.7.7 0 0 1-.21.13 1.61 1.61 0 0 1-1.9 0 .7.7 0 0 1-.21-.13l-.78-.69-6.63 7.34a1.8 1.8 0 0 0 .57.1h16a1.8 1.8 0 0 0 .57-.1l-6.63-7.37Zm-1.32-1.11a.24.24 0 0 0 .3 0l8.43-7.49a1.7 1.7 0 0 0-.57-.15H4a1.7 1.7 0 0 0-.59.11l8.43 7.49Z"
      ></path>
    </svg>
  );

  return (
    <div className="bg-gray-50">
      <div className="text-center pt-4 justify-center">
        <div className="inline-block text-3xl font-medium px-4 pt-2 rounded-lg mb-4">
          Our Locations
        </div>
        <div className="flex items-center justify-center">
          <div className="w-10 h-0.5 bg-green-600"></div>
        </div>
      </div>
      <div className="flex flex-col md:flex-row justify-center gap-8 p-8 bg-gray-50">
        {addresses.map((address, index) => (
          <div
            key={index}
            className="bg-white rounded-2xl shadow-md p-6 w-full max-w-sm hover:shadow-xl transition-shadow duration-300 flex flex-col"
          >
            <div className="flex-grow flex flex-col items-center h-38 justify-between">
              <img
                src={address?.image}
                alt="Nouka Logo"
                className={address?.className}
              />
              <h3 className="text-xl font-semibold text-gray-800 mt-6 mb-4 border-b pb-2 text-center">
                {address.country}
              </h3>
            </div>
            <div className="text-gray-700 space-y-3 flex-grow flex flex-row gap-2">
              <MapPinIcon />
              <div className="w-100 items-center">
                {address.addressLines.map((line, idx) => (
                  <p key={idx} className="mb-1">
                    {line}
                  </p>
                ))}
              </div>
            </div>
            <div className="flex items-center mt-4">
              <MailIcon />
              <a
                href={`mailto:${address.email}`}
                className="text-gray-800 font-medium hover:underline"
              >
                {address.email}
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

/* Testimonial Section */

const TestimonialNew = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedTestimonial, setSelectedTestimonial] = useState("");

  const openModal = (testimonial) => {
    setSelectedTestimonial(testimonial);
    setIsOpen(true);
  };

  return (
    <div
      id="our-work"
      className="flex bg-slate-50 items-center justify-center w-full mx-auto flex-col p-8 overflow-x-hidden"
    >
      <div className="text-center mt-8">
        <div className="inline-block text-3xl font-medium px-4 pt-2 rounded-lg mb-4 text-black">
          Testimonial
        </div>
        <div className="w-10 h-0.5 bg-green-600 mx-auto mt-0 mb-32"></div>
      </div>

      <div className="flex flex-wrap items-stretch gap-12 mt-6 justify-center w-full">
        {[
          {
            name: "PRABAHARAN VENUGOPAL",
            title: "Founder, Cuptime",
            text: "Team Noukha did an exceptional job building my MVP, delivering it quickly and providing excellent support throughout. Impressed by their expertise and efficiency, I entrusted them with developing the full product, including mobile apps (Delivery and Customer) and a web app. They understood my vision effortlessly and turned it into the perfect startup product. I highly recommend their team for their dedication and startup expertise.",
            imgSrc: cuptimePrabakharan,
            linkedinUrl:
              "https://www.linkedin.com/in/prabaharanpv/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
          },
          {
            name: "ARUN NAIR",
            title: "CEO, Startup Singam",
            text: "We had to build a website from scratch within a tight deadline, with only a basic outline of what we needed. The team at Noukha approached the challenge with great enthusiasm, ensuring they thoroughly understood our requirements while suggesting the best possible solutions. Given that this was a new concept with entirely unique requirements, Ram and his team demonstrated an adventurous spirit by taking on the project. The biggest challenge was the extremely short timeframe, yet they successfully delivered a fully functional website, integrating at least three API call-outs and multiple form integrations. Kudos to the entire Noukha team for an exceptionally well-executed project! The design, user flow, and overall execution were seamless, allowing us to launch without any hiccups. I highly recommend them for any complex and time-sensitive web development projects!",
            imgSrc: startupsingamarun,
            linkedinUrl: "https://www.linkedin.com/in/an2512",
          },
        ].map((testimonial, index) => (
          <div
            key={index}
            className="flex flex-col items-center justify-between relative w-[420px] max-w-full"
          >
            <div className="w-full h-72 bg-slate-50 flex items-center justify-center">
              <div className="w-[360px] h-72 absolute bottom-24 bg-white p-6 rounded-tl-[4vw] rounded-br-[4vw] text-center hover:scale-105 shadow-lg">
                <h1 className="text-green-500 font-bold pt-3 flex items-center justify-center gap-2">
                  <span className="text-black">
                    {testimonial.name.split(" ")[0]}
                  </span>{" "}
                  {testimonial.name.split(" ")[1]}
                  <a
                    href={testimonial.linkedinUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={linkedin} alt="LinkedIn" className="w-5 h-5" />
                  </a>
                </h1>
                <h1 className="pb-2">{testimonial.title}</h1>
                <p
                  className="italic text-sm text-center text-gray-600 cursor-pointer"
                  onClick={() => openModal(testimonial.text)}
                >
                  {testimonial.text.substring(0, 265)}...{" "}
                  <span className="text-black">Read More</span>
                </p>
                <div className="rounded-full w-16 h-16 bg-black absolute -bottom-6 left-1/2 -translate-x-1/2 flex items-center justify-center shadow-lg">
                  <img
                    src={testimonial.imgSrc}
                    alt={testimonial.name}
                    className="w-16 border border-black bg-white rounded-full"
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Modal */}
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        className="fixed inset-0 flex items-center justify-center p-4 bg-black bg-opacity-50"
      >
        <Dialog.Panel className="bg-white p-6 rounded-lg max-w-md text-center">
          <Dialog.Title className="text-lg font-bold italic">Testimonial</Dialog.Title>
          <Dialog.Description className="mt-2 text-gray-600 italic">
            {selectedTestimonial}
          </Dialog.Description>
          <button
            className="mt-4 bg-green-500 text-white px-4 py-2 rounded-lg"
            onClick={() => setIsOpen(false)}
          >
            Close
          </button>
        </Dialog.Panel>
      </Dialog>
    </div>
  );
};

/*More Blogs*/
// const MoreStep = ({navigate}) => {
//   const addresses = [
//     {
//       Title: "Join Our Jorney",
//       image: star,
//       description:
//         "We design and develop responsive, SEO-friendly websites that align with your brand identity, enhancing your online presence effectively.",
//     },
//   ];
//   // <button 
//   //       onClick={() =>{
//   //       navigate("/Blogs");
//   //     }}
//   //     >blogs</button> 
//   return (
//     <div className="bg-gray-50">
//       <div className="text-center pt-4 justify-center">
//         <div className="inline-block text-3xl font-medium px-4 pt-2 rounded-lg mb-4">
//           Our More Step
//         </div>
//         <div className="w-20 h-0.5 bg-green-600 mx-auto mt-0"></div>
//         {/* <div className="flex items-center justify-center">
//         </div> */}
//       </div>
//       <div className="flex flex-col md:flex-row justify-center gap-8 p-8 bg-gray-50">
//         {addresses.map((address, index) => (
//           <div
//             key={index}
//             className="bg-white rounded-2xl shadow-md p-6 w-full max-w-sm hover:shadow-xl transition-shadow duration-300 flex flex-col"
//           >
//             <div className="flex-grow flex flex-col items-center h-38 justify-between">
//               <h3 className="text-xl font-semibold text-gray-800 mt-6 mb-4 border-b pb-2 text-center">
//                 {address.Title}
//               </h3>
//               <h3 className="font text-gray-800 mt-6 mb-4 border-b pb-2 text-center">
//                 {address.description}
//               </h3>
//               <img src={address.image}
//                 alt="" className="w-70 h-full justify-center" />
//             </div>
//             <button className="text-xl text-center text-black px-4 py-1 bg-gray-100 sm:px-5 sm:py-2 md:px-6 md:py-3 mt-4 rounded-full hover:bg-gray-500 transition duration-300 ease-in-out relative z-10 text-xs sm:text-sm md:text-base"
//         onClick={() => {
//         navigate("/Blogs");
//       }}>BLOGS</button> 
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };
/*testimonials section end*/

// const Blog = () => {
//   const navigate = useNavigate();

// Function to handle button click and navigate to the blogs page
//   const goToBlogs = () => {
//     navigate('/Blogs');
//   // };

//   return (
//     <div className="flex items-center justify-center min-h-screen bg-gray-100">
//       {/* Card Container */}
//       <div className="bg-white p-8 rounded-lg shadow-lg w-96">
//         <h2 className="text-2xl font-semibold text-gray-800 mb-4">Welcome to Our Website</h2>
//         <p className="text-gray-600 mb-6">
//           This is a simple card on the homepage. Click the button below to explore our blogs.
//         </p>
//         <button
//           onClick={goToBlogs}
//           className="w-full py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition duration-300"
//         >
//           Go to Blogs
//         </button>
//       </div>
//     </div>
//   );
// };
// };

// export default Blog;

const HomePage = memo(() => {
  const navigate = useNavigate();
  const handleScroll = (e, id) => {
    e.preventDefault();
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };
  return (
    <>
      <Header handleScroll={handleScroll} />
      <HeroSection handleScroll={handleScroll} />
      <WhoWeAreSection2></WhoWeAreSection2>
      <WhoWeAreSection handleScroll={handleScroll} />
      {/* <OurServices navigate={navigate} /> */}
      {/* <OurBlog navigate={navigate}/> */}
      {/* <ChevronRight> */}
      <OurClientsV2 handleScroll={handleScroll} navigate={navigate} />
      {/* <ChevronRight/> */}
      {/* <MoreStep navigate={navigate}/> */}
      {/* <BlogDetails navigate={navigate}/> */}
      {/* <OurServices handleScroll={handleScroll} /> */}
      {/* <OurWorks handleScroll={handleScroll} /> */}
      <TestimonialNew handleScroll={handleScroll} />
      {/*<OurClients handleScroll={handleScroll} />*/}
      <PricingCards handleScroll={handleScroll} />
      {/* <CaseStudies handleScroll={handleScroll} /> */}

      {/* <section id="contact-us">
        {!isIndianIP && (
          <ContactForm handleScroll={handleScroll} />
        )}
      </section> */}
      {/* <MoreStep/> */}
      <OfficeAddresses />
      {/* <div className="bg-gray-100 pb-4 pt-4">
        <div className="max-w-6xl mx-auto px-1 sm:px-2 lg:px-4">
          <div id="why-choose-us" className="text-center mt-10 mb-8">
        
            <div className="inline-block text-3xl font-medium px-4 pt-2 rounded-lg mb-4">
              Trusted By
            </div>
    
  
            <div className="flex items-center justify-center mb-4">
              <div className="w-10 h-0.5 bg-green-600"></div>
            </div>
            
            <div className="flex flex-col md:flex-row justify-center gap-4 p-4">
           
              <div className="border border-green-600 rounded-lg w-full md:w-auto">
                <div
                 className="clutch-widget w-[270px] md:w-[270px] mt-1"
                  class="goodfirm-widget"
                  data-widget-type="goodfirms-widget-t8"
                  data-widget-pattern="poweredby-star"
                  data-height="60"
                  data-company-id="167451"
                ></div>
              </div>

              <div className="border border-green-600 rounded-lg flex flex-col md:flex-row justify-center items-center p-4">
                <div
                  className="clutch-widget w-[200px] md:w-[200px]"
                  data-url="https://widget.clutch.co"
                  data-widget-type="2"
                  data-height="45"
                  data-nofollow="true"
                  data-expandifr="true"
                  data-clutchcompany-id="2466878"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* <button 
        onClick={() =>{
        navigate("/Blogs");
      }
      }>blogs</button> */}
      <Footer handleScroll={handleScroll} />

      {/* <TawkToWidget /> */}
    </>
  );
});

export default HomePage;
